<script>
import Notify from '@/shared/utils/notify';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import { isObjectEmpty } from '@/shared/utils/globalMethods';

export default {
  name: 'QuestionCard',
  props: {
    index: {
      type: Number,
    },
    question: {
      type: Object,
    },
    // Defines a callback function which can be used to update parent state on change event
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction: String,
    // Defines params for callback function or callback action
    callbackParams: {
      type: Object,
    },
    // Defines a callback function which can be used to update parent state on change event
    callback2: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction2: String,
    // Defines params for callback function or callback action
    callbackParams2: {
      type: Object,
    },
    to: String,
  },
  methods: {
    deleteCard(id) {
      const deleteUserPath = '/WebRemoveQuestion.php';
      const data = new FormData();
      data.append('id', id);
      fetch(server + deleteUserPath, {
        method: 'POST',
        headers: {
          WebToken: getToken(),
        },
        body: data,
      }).then((response) => response.json()).then((json) => {
        if (json.status === 'error') {
          if (json.error.includes('Wrong token')) {
            this.$router.push({ name: 'login' });
            destroyToken();
          } else {
            Notify('warning', json.error);
          }
        }
        if (json.status === 'ok') {
          this.clickCallback();
        }
      });
    },
    clickCallback() {
      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }
      }
    },
    clickCallback2() {
      if (this.callback2) {
        if (!isObjectEmpty(this.callbackParams2)) {
          this.callback2(this.callbackParams2);
        } else {
          this.callback2();
        }
      }
    },
  },
};
</script>
<template>
  <div class="question-container"
>
  <div class="headline-bold-16 basic-2" style="margin-bottom: 16px">
    {{ $t('GENERAL.QUESTION') }} {{ index + 1 }}

    <div class="delete-card" v-on:click="deleteCard(question.id)">
      <unicon name="trash-alt" fill="#BDBDBD" height="18" width="18"/>
    </div>
    <div class="edit-card" v-on:click="clickCallback2">
      <unicon name="edit-alt" fill="#FFFFFF" height="18" width="18"/>
    </div>
  </div>
  <div style="width: 100%;" v-if="question.image">
    <img style="max-width: 100%;margin-bottom: 16px;border-radius: 8px"
         :src="question.image ? question.image : ''"/>
  </div>
  <div style="display:inline-flex;width: 100%;margin-bottom: 16px">
    <div class="time headline-medium-16" style="min-width: 71px!important;">

      <unicon name="question-circle" fill="#D37B05" height="16" width="16"/>
    </div>
    <div class="headline-bold-16 basic-2">
      {{ question.title }}
    </div>
  </div>
  <div>
    <div style="display:inline-flex;width: 100%;margin-bottom: 16px"
         v-for="(answer,j) in question.answers" :key="j">
      <div :class="'headline-medium-16 index' +
                     (answer.right === true ? '-correct': '')">

        <unicon v-if="answer.right === true"
                name="check-circle" fill="#00CA72" height="16" width="16"/>
        <unicon v-if="answer.right !== true"
                name="times" fill="#D37B05" height="16" width="16"/>
      </div>
      <div class="headline-regular-16 basic-2"> {{answer.answer}}</div>
    </div>
  </div>
</div>
</template>
<style lang="scss">
.question-container .delete-card {
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  right: 46px!important;
  top: 8px;
  left: unset!important;
}

.question-container .time {
  padding: 4px 6px;
  height: 27px;
  background: #FFF7D9;
  border-radius: 4px;
  margin-right: 16px;
  color: #D37B05;
  width: 71px;
  text-align: center;
}
.question-container .index {
  padding: 4px;
  height: 27px;
  background: rgba(226, 68, 92, 0.1);
  border-radius: 4px;
  color: #E2445C;
  width: 71px;
  text-align: center;
  margin-right: 16px;
  min-width: 71px!important;
}
.question-container .index-correct {
  padding: 4px;
  height: 27px;
  background: rgba(0, 202, 114, 0.1);
  border-radius: 4px;
  color: #00CA72;
  width: 71px;
  min-width: 71px!important;
  text-align: center;
  margin-right: 16px;
}
.edit-card {
  padding: 6px;
  background: #1694D0;
  border-radius: 8px;
  width: fit-content;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
}
.delete-card svg,.edit-card svg {
  height: 18px;
  width: 18px;
  margin-top: -2px;
}
</style>
